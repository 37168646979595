.agentDetails {
    margin-top: 1em;
}

.backArrow {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 1em;
    color: #000;
    font-weight: 100;
    padding: 0 10px 3px 10px;
    border-radius: 50%;
    background-color: #fff !important;
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.adminPortal {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 20em;
    margin-top: 5em;
}

.filter {
    margin-right: 11.6em;
}

.detailsPortion {
    margin-top: 0;
    display: flex;
    flex-direction: row;
}

.text4 {
    font-weight: bold;
    font-size: medium;
    font-family: Arial;
    color: #64bb46;
}

.ml5 {
    margin-left: 5em;
}

.text-line-truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


.pointsPortation {
    display: flex;
    flex-direction: row;
}

.activeCard {
    border: 1.5px solid #64bb46 !important;
    transition: border 1s;
}

.pointCard {
    cursor: pointer;
    width: 9em;
    min-height: 12em;
    margin: 5px 13px;
    border-radius: 5px !important;
}

.pointCard:first-child {
    margin-left: 0;
}

.pointCard:last-child {
    margin-right: 0;
}

.editIcon {
    color: #64bb46;
    margin-left: 83%;
    margin-top: 5%;
    height: 15px;
    width: 15px;
}

/* .userDetails {
    display: flex;
    flex-direction: row;
} */

.userDetailsCard {
    width: 50%
}

.userPic {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

.m-5 {
    margin: 10px !important;
}

.ml-10 {
    margin-left: 10em;
}

.justify-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-0 {
    flex: 0 1 0%;
}

.m-0 {
    margin: 0 !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}


.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.flex-column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.text-center {
    text-align: center;
}

.card-container {
    height: 260px;
}

.card {
    margin: 1em;
    overflow: visible !important;
}

.boldText {
    font-weight: bold;
}

.rightText {
    float: right;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
}

.arrow-up-relative {
    position: relative;
    top: 21px;
    left: 25%;
}

.detailsEditIcon {
    height: 15px;
    width: 15px;
}

.indicator-section {
    width: 995px;
}

.indicator_container {
    flex-wrap: wrap;
}

.indicator-wight {
    margin: 0 5px;
}

.rowIndicators {
    display: flex;
    flex-direction: row;
    min-width: 50%;
}

.percentage {
    background-color: #888;
    /* margin: 5px 0 5px 0; */
    margin-left: 1em;
    padding: 0 10px 0 10px;
    border-radius: 21px;
    color: #fff;
}

.pointsCards {
    display: flex;
    flex-direction: column;
    margin-left: 5em;
}

.userCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 70px;
}

.userName {
    margin-left: 1em;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.monthBadge {
    padding: 5px;
    border-radius: 5px;
    background-color: #dcf0d5;
}

.businessData {
    padding: 0.7em;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.sm-text {
    font-size: 13px;
}

.md-text {
    font-size: 21px;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
}

.align-item-center {
    align-items: center;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.flex-row {
    margin: 0 -15px;
}

.calendar {
    cursor: pointer;
}

.search-container {
    position: absolute;
    width: 210px;
    /* display: block; */
    margin: 0 auto;
    /* margin-left: 5px; */
}

.searchBox {
    margin-left: 1em;
    padding: 5px;
}

.td-none {
    text-decoration: none;
}

.year-container {
    width: 100px;
    margin: 0 auto;
    /* position: absolute;
    display: block;
    margin-left: 5px; */
}

.mt-7 {
    margin-top: -7px;
}

.mr-5 {
    margin-right: 5em;
}

.dialogInput {
    border: 1px solid #aaa;
    border-radius: 5px;
    width: 170px;
    color: #aaa;
    padding: 15px;
    font-size: 16px;
}

.defaultBtn {
    background-color: #fff !important;
    color: #555;
    font-weight: bold;
}

.bar {
    margin-top: 8px;
    margin-bottom: 4px;
}

.indicator-card-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    height: 60px;
}

.indicator-card-header .year-container {
    position: relative;
    display: block;
    margin: 0;
    width: 132px;
}

.indicator-card-header #search-bar {
    height: 32px;
}

.indicator-card-header .search-icon {
    position: absolute;
    top: 7px;
    right: 8px;
    height: auto;
}

.view-more-btn {
    display: inline-flex;
    margin-top: 30px !important;
}

.MuiSnackbarContent-message .material-icons {
    margin-right: 3px;
}

.monetary-field input {
    padding: 0;
}

.monetary-field fieldset {
    border: 0;
}

.sp-card {
    min-height: 210px;
    width: 140px !important;
}

.sp-slider {
    width: 995px;
}

.slick-slide {
    max-width: 146px !important;
    margin: 5px 7px 1px 7px !important;
}

.slick-prev:before,
.slick-next:before {
    color: #64bb46 !important;
}