@font-face {
  font-family: 'Circular Std Black';
  src: url('./fonts/CircularStd-Black.eot');
  src: url('./fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CircularStd-Black.woff2') format('woff2'),
    url('./fonts/CircularStd-Black.woff') format('woff'),
    url('./fonts/CircularStd-Black.ttf') format('truetype'),
    url('./fonts/CircularStd-Black.otf') format('opentype'),
    url('./fonts/CircularStd-Black.svg#CircularStd-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'Circular Std Bold';
  src: url('./fonts/CircularStd-Bold.eot');
  src: url('./fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CircularStd-Bold.woff2') format('woff2'),
    url('./fonts/CircularStd-Bold.woff') format('woff'),
    url('./fonts/CircularStd-Bold.ttf') format('truetype'),
    url('./fonts/CircularStd-Bold.otf') format('opentype'),
    url('./fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'Circular Std Book';
  src: url('./fonts/CircularStd-Book.eot');
  src: url('./fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CircularStd-Book.woff2') format('woff2'),
    url('./fonts/CircularStd-Book.woff') format('woff'),
    url('./fonts/CircularStd-Book.ttf') format('truetype'),
    url('./fonts/CircularStd-Book.otf') format('opentype'),
    url('./fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: 450;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'Circular Std Medium';
  src: url('./fonts/CircularStd-Medium.eot');
  src: url('./fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CircularStd-Medium.woff2') format('woff2'),
    url('./fonts/CircularStd-Medium.woff') format('woff'),
    url('./fonts/CircularStd-Medium.ttf') format('truetype'),
    url('./fonts/CircularStd-Medium.otf') format('opentype'),
    url('./fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

body {
  font-family: 'Circular Std Book';
}

h1,
h2,
h3,
h4 {
  font-family: 'Circular Std Medium' !important;
}

.MuiTableCell-head {
  font-family: 'Circular Std Medium' !important;
}

.MuiTableCell-body,
.MuiFormControlLabel-label {
  font-family: 'Circular Std Book' !important;
}

input,
select,
p {
  font-family: 'Circular Std Book' !important;
}

* {
  box-sizing: border-box;
}

.logo {
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
}

.form {
  padding-top: 3%;
}

.container {
  display: block;
  position: absolute;
  margin-left: 2.5%;
  bottom: 25%;
  padding-left: 35px;
  cursor: pointer;
  font-size: calc(0px + 1.5vmin);
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.chk-box span {
  vertical-align: sub;
  font-size: 11px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #64bb46;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input {
  border: 1px solid #aaa;
  border-radius: 2px;
  background-color: #f8f8fa;
  color: #aaa;
  padding: 9px;
  margin: 9px;
  font-size: calc(5px + 2vmin);
}

input::placeholder {
  color: #aaa;
  font-size: calc(0px + 1.8vmin);
}

.error {
  color: #ff0000;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}

.success {
  color: green;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}

.btn {
  border: 0;
  padding: 10px 30px 10px 30px;
  margin-left: 35%;
  border-radius: 3px;
  background-color: #64bb46;
  color: #fff;
  font-size: calc(0px + 1.5vmin);
  cursor: pointer;
}

.sticky-overflow {
  overflow-y: overlay;
}

@font-face {
  font-family: 'Circular Std Black';
  src: url('./fonts/CircularStd-Black.eot');
  src: url('./fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CircularStd-Black.woff2') format('woff2'),
    url('./fonts/CircularStd-Black.woff') format('woff'),
    url('./fonts/CircularStd-Black.ttf') format('truetype'),
    url('./fonts/CircularStd-Black.otf') format('opentype'),
    url('./fonts/CircularStd-Black.svg#CircularStd-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'Circular Std Bold';
  src: url('./fonts/CircularStd-Bold.eot');
  src: url('./fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CircularStd-Bold.woff2') format('woff2'),
    url('./fonts/CircularStd-Bold.woff') format('woff'),
    url('./fonts/CircularStd-Bold.ttf') format('truetype'),
    url('./fonts/CircularStd-Bold.otf') format('opentype'),
    url('./fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'Circular Std Book';
  src: url('./fonts/CircularStd-Book.eot');
  src: url('./fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CircularStd-Book.woff2') format('woff2'),
    url('./fonts/CircularStd-Book.woff') format('woff'),
    url('./fonts/CircularStd-Book.ttf') format('truetype'),
    url('./fonts/CircularStd-Book.otf') format('opentype'),
    url('./fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: 450;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'Circular Std Medium';
  src: url('./fonts/CircularStd-Medium.eot');
  src: url('./fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CircularStd-Medium.woff2') format('woff2'),
    url('./fonts/CircularStd-Medium.woff') format('woff'),
    url('./fonts/CircularStd-Medium.ttf') format('truetype'),
    url('./fonts/CircularStd-Medium.otf') format('opentype'),
    url('./fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

body {
  font-family: 'Circular Std Book';
}

h1,
h2,
h3,
h4 {
  font-family: 'Circular Std Medium' !important;
}

.MuiTableCell-head {
  font-family: 'Circular Std Medium' !important;
}

.MuiTableCell-body,
.MuiFormControlLabel-label {
  font-family: 'Circular Std Book' !important;
}

input,
select,
p {
  font-family: 'Circular Std Book' !important;
}

* {
  box-sizing: border-box;
}

.logo {
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
}

.form {
  padding-top: 3%;
}

.container {
  display: block;
  position: absolute;
  margin-left: 2.5%;
  bottom: 25%;
  padding-left: 35px;
  cursor: pointer;
  font-size: calc(0px + 1.5vmin);
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.chk-box span {
  vertical-align: sub;
  font-size: 11px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #64bb46;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input {
  border: 1px solid #aaa;
  border-radius: 2px;
  background-color: #f8f8fa;
  color: #aaa;
  padding: 9px;
  margin: 9px;
  font-size: calc(5px + 2vmin);
}

input::placeholder {
  color: #aaa;
  font-size: calc(0px + 1.8vmin);
}

.error {
  color: #ff0000;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}

.success {
  color: green;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}

.btn {
  border: 0;
  padding: 10px 30px 10px 30px;
  margin-left: 35%;
  border-radius: 3px;
  background-color: #64bb46;
  color: #fff;
  font-size: calc(0px + 1.5vmin);
  cursor: pointer;
}

